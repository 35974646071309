
import {
	computed,
	defineComponent,
	getCurrentInstance,
	onMounted,
	reactive,
	ref
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../store'
import AdminLayout from '../global-components/layouts/AdminLayout.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import mixpanel from 'mixpanel-browser'
import IqProApi from '@/apis/IqProApi'
import * as mp from '../apis/mixpanel'
import { GroupProperty } from '@/types/mixpanel'

export default defineComponent({
	name: 'AdminSimplePayListView',
	components: {
		AdminLayout
	},
	setup() {
		const store = useStore()

		const router = useRouter()
		const formData = reactive({
			filterString: '',
			showInactives: false
		})
		const distinctId = ref('')
		// let awaitingSearch = ref(false)
		let searchedValues = ref([])

		const username = computed(() => store.state.username)
		const storedData = computed(() => store.state.simplePayData)
		const app = getCurrentInstance()
		const simplePayData = computed(() => {
			if (!storedData.value || !storedData.value.length) {
				return false
			}
			let formattedData = storedData.value.map((merchant: any) => {
				return {
					fpMerchantId: merchant.text.merchantId
						? merchant.text.merchantId
						: null,
					merchantName:
						merchant.text.simplePaySettings &&
						merchant.text.simplePaySettings.merchantName
							? merchant.text.simplePaySettings.merchantName
							: 'Unavailable',
					basysMID: merchant.text.basysMID
						? merchant.text.basysMID
						: null,
					simplePayId: merchant._id ? merchant._id : null,
					isActive:
						merchant.text.simplePaySettings &&
						merchant.text.simplePaySettings.isActive
							? merchant.text.simplePaySettings.isActive
							: false
				}
			})

			formattedData.sort((a: any, b: any) => {
				if (a.isActive && !b.isActive) return 1
				if (!a.isActive && b.isActive) return -1

				if (a.fpMerchantId > b.fpMerchantId) return 1
				if (a.fpMerchantId < b.fpMerchantId) return -1

				if (a.merchantName > b.merchantName) return 1
				if (a.merchantName < b.merchantName) return -1
			})

			return formattedData
		})

		const filteredData = computed(() => {
			const showInactives = formData.showInactives
			const filterString = formData.filterString.toLowerCase()

			const list = simplePayData.value
			let filteredList: Array<any> = []
			// this removes a type error we were getting in the browser

			if (list && list.length) {
				filteredList = list.filter((item: any) => {
					const hideItem = !showInactives && !item.isActive

					return (
						((item.merchantName &&
							item.merchantName
								.toLowerCase()
								.includes(filterString)) ||
							(item.basysMID &&
								item.basysMID
									.toLowerCase()
									.includes(filterString)) ||
							(item.simplePayId &&
								item.simplePayId
									.toLowerCase()
									.includes(filterString))) &&
						!hideItem
					)
				})
			}
			return filteredList
		})

		onMounted(async () => {
			await store.dispatch('getSimplePayData').then(() => {
				store.dispatch('hideLoadingScreen')
			})

			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)
		})
		async function searchAfterEnter() {
			searchedValues.value.push(formData.filterString)
			mp.searchedValues(searchedValues.value)
		}

		async function deactivateListItem(simplePayId: string) {
			const resp = await IqProApi.getSimplePay(simplePayId)
			const merchantName = resp.data.text.simplePaySettings.merchantName

			ElMessageBox.confirm(
				'This will deactivate the selected SimplePay page. It can be reactivated later.',
				'Are you sure?',
				{
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel',
					type: 'warning'
				}
			)
				.then(() => {
					store.dispatch('toggleSimplePayActive', simplePayId)
					ElMessage({
						type: 'success',
						message: 'The page has been deactivated'
					})

					mp.deactivatePagePeopleCount()

					const groupData: GroupProperty = {
						simplePayId: simplePayId,
						merchantName: merchantName,
						deactivated: true,
						deactivatedBy: username.value,
						activated: false
					}
					mp.setDeactivatedPage(groupData)
				})
				.catch(() => {
					ElMessage({
						type: 'info',
						message: 'The page was not deactivated'
					})
				})
		}
		async function activateListItem(simplePayId: string) {
			store.dispatch('toggleSimplePayActive', simplePayId)

			const resp = await IqProApi.getSimplePay(simplePayId)
			const merchantName = resp.data.text.simplePaySettings.merchantName

			mp.activatePagePeopleCount()
			const groupData: GroupProperty = {
				simplePayId: simplePayId,
				merchantName: merchantName,
				deactivated: false,
				activatedBy: username.value,
				activated: true
			}
			mp.setActivatedPage(groupData)
		}

		function disabledState({ row }: any) {
			if (!row.isActive) {
				return 'is-disabled'
			}
			return ''
		}
		function goToCreate() {
			showLoad()
			router.push({ name: 'adminCreate' })
		}
		function showLoad() {
			store.dispatch('showLoadingScreen')
			mp.adminEditViewCountPeopleProp()
		}

		return {
			formData,
			activateListItem,
			deactivateListItem,
			disabledState,
			filteredData,
			goToCreate,
			showLoad,
			searchAfterEnter
		}
	}
})
