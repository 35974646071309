<template>
	<admin-layout>
		<el-main class="form-wrapper admin-master">
			<h1>SimplePay Admin</h1>
			<el-form
				ref="adminListViewFilters"
				label-position="top"
				:model="formData"
				size="small"
				@submit.prevent
			>
				<div class="input-group" data-filter>
					<el-row :gutter="15">
						<el-col :xs="24" :sm="12">
							<el-form-item label="Search" prop="filterString">
								<el-input
									v-model="formData.filterString"
									type="text"
									class="search-filter"
									clearable
									data-cy="admin-search"
									@change="searchAfterEnter"
								></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8" class="txt-c">
							<el-checkbox
								v-model="formData.showInactives"
								class="show-inactive"
								data-cy="admin-inactive-checkbox"
								>Show Inactive Records</el-checkbox
							>
						</el-col>
						<el-col :xs="24" :sm="4">
							<el-form-item class="createBtnWrap">
								<el-button
									ref="createButton"
									class="create-button"
									type="success"
									data-cy="admin-create-button"
									@click="goToCreate"
								>
									<span class="plus-icon">&plus;</span>Add
									Page
								</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</div>
			</el-form>

			<el-table
				:data="filteredData"
				style="width: 100%"
				max-height="650"
				:row-class-name="disabledState"
				data-cy="admin-table"
			>
				<el-table-column
					label="Merchant Name"
					prop="merchantName"
					width="auto"
					min-width="200"
					data-cy="admin-merchant-name"
				></el-table-column>
				<el-table-column
					label="Basys MID"
					prop="basysMID"
					width="150"
					data-cy="admin-basys-mid"
				></el-table-column>
				<el-table-column
					label="SimplePay ID"
					prop="simplePayId"
					width="200"
					data-cy="admin-simplepayid"
				></el-table-column>
				<el-table-column label="Actions" width="90" fixed="right">
					<template #default="scope">
						<router-link
							v-show="scope.row.isActive"
							:to="{
								name: 'adminEdit',
								params: { id: scope.row.simplePayId }
							}"
						>
							<el-button
								data-cy="admin-edit-button"
								type="primary"
								plain
								icon="el-icon-edit"
								class="edit-btn"
								circle
								@click="showLoad()"
							></el-button>
						</router-link>
						<el-button
							v-show="scope.row.isActive"
							data-cy="admin-delete-button"
							type="danger"
							plain
							icon="el-icon-delete"
							class="delete-btn"
							circle
							@click="deactivateListItem(scope.row.simplePayId)"
						></el-button>
						<el-button
							v-show="!scope.row.isActive"
							data-cy="admin-isActive"
							type="success"
							plain
							icon="el-icon-check"
							class="activate-btn"
							circle
							@click="activateListItem(scope.row.simplePayId)"
						></el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-main>
	</admin-layout>
</template>

<script lang="ts">
import {
	computed,
	defineComponent,
	getCurrentInstance,
	onMounted,
	reactive,
	ref
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from '../store'
import AdminLayout from '../global-components/layouts/AdminLayout.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import mixpanel from 'mixpanel-browser'
import IqProApi from '@/apis/IqProApi'
import * as mp from '../apis/mixpanel'
import { GroupProperty } from '@/types/mixpanel'

export default defineComponent({
	name: 'AdminSimplePayListView',
	components: {
		AdminLayout
	},
	setup() {
		const store = useStore()

		const router = useRouter()
		const formData = reactive({
			filterString: '',
			showInactives: false
		})
		const distinctId = ref('')
		// let awaitingSearch = ref(false)
		let searchedValues = ref([])

		const username = computed(() => store.state.username)
		const storedData = computed(() => store.state.simplePayData)
		const app = getCurrentInstance()
		const simplePayData = computed(() => {
			if (!storedData.value || !storedData.value.length) {
				return false
			}
			let formattedData = storedData.value.map((merchant: any) => {
				return {
					fpMerchantId: merchant.text.merchantId
						? merchant.text.merchantId
						: null,
					merchantName:
						merchant.text.simplePaySettings &&
						merchant.text.simplePaySettings.merchantName
							? merchant.text.simplePaySettings.merchantName
							: 'Unavailable',
					basysMID: merchant.text.basysMID
						? merchant.text.basysMID
						: null,
					simplePayId: merchant._id ? merchant._id : null,
					isActive:
						merchant.text.simplePaySettings &&
						merchant.text.simplePaySettings.isActive
							? merchant.text.simplePaySettings.isActive
							: false
				}
			})

			formattedData.sort((a: any, b: any) => {
				if (a.isActive && !b.isActive) return 1
				if (!a.isActive && b.isActive) return -1

				if (a.fpMerchantId > b.fpMerchantId) return 1
				if (a.fpMerchantId < b.fpMerchantId) return -1

				if (a.merchantName > b.merchantName) return 1
				if (a.merchantName < b.merchantName) return -1
			})

			return formattedData
		})

		const filteredData = computed(() => {
			const showInactives = formData.showInactives
			const filterString = formData.filterString.toLowerCase()

			const list = simplePayData.value
			let filteredList: Array<any> = []
			// this removes a type error we were getting in the browser

			if (list && list.length) {
				filteredList = list.filter((item: any) => {
					const hideItem = !showInactives && !item.isActive

					return (
						((item.merchantName &&
							item.merchantName
								.toLowerCase()
								.includes(filterString)) ||
							(item.basysMID &&
								item.basysMID
									.toLowerCase()
									.includes(filterString)) ||
							(item.simplePayId &&
								item.simplePayId
									.toLowerCase()
									.includes(filterString))) &&
						!hideItem
					)
				})
			}
			return filteredList
		})

		onMounted(async () => {
			await store.dispatch('getSimplePayData').then(() => {
				store.dispatch('hideLoadingScreen')
			})

			distinctId.value =
				app.appContext.config.globalProperties.$distinct_id
			mixpanel.identify(distinctId.value)
		})
		async function searchAfterEnter() {
			searchedValues.value.push(formData.filterString)
			mp.searchedValues(searchedValues.value)
		}

		async function deactivateListItem(simplePayId: string) {
			const resp = await IqProApi.getSimplePay(simplePayId)
			const merchantName = resp.data.text.simplePaySettings.merchantName

			ElMessageBox.confirm(
				'This will deactivate the selected SimplePay page. It can be reactivated later.',
				'Are you sure?',
				{
					confirmButtonText: 'OK',
					cancelButtonText: 'Cancel',
					type: 'warning'
				}
			)
				.then(() => {
					store.dispatch('toggleSimplePayActive', simplePayId)
					ElMessage({
						type: 'success',
						message: 'The page has been deactivated'
					})

					mp.deactivatePagePeopleCount()

					const groupData: GroupProperty = {
						simplePayId: simplePayId,
						merchantName: merchantName,
						deactivated: true,
						deactivatedBy: username.value,
						activated: false
					}
					mp.setDeactivatedPage(groupData)
				})
				.catch(() => {
					ElMessage({
						type: 'info',
						message: 'The page was not deactivated'
					})
				})
		}
		async function activateListItem(simplePayId: string) {
			store.dispatch('toggleSimplePayActive', simplePayId)

			const resp = await IqProApi.getSimplePay(simplePayId)
			const merchantName = resp.data.text.simplePaySettings.merchantName

			mp.activatePagePeopleCount()
			const groupData: GroupProperty = {
				simplePayId: simplePayId,
				merchantName: merchantName,
				deactivated: false,
				activatedBy: username.value,
				activated: true
			}
			mp.setActivatedPage(groupData)
		}

		function disabledState({ row }: any) {
			if (!row.isActive) {
				return 'is-disabled'
			}
			return ''
		}
		function goToCreate() {
			showLoad()
			router.push({ name: 'adminCreate' })
		}
		function showLoad() {
			store.dispatch('showLoadingScreen')
			mp.adminEditViewCountPeopleProp()
		}

		return {
			formData,
			activateListItem,
			deactivateListItem,
			disabledState,
			filteredData,
			goToCreate,
			showLoad,
			searchAfterEnter
		}
	}
})
</script>

<style lang="scss" scoped>
.el-table {
	:deep(tr.is-disabled) {
		background-color: #f7f7f7;
	}

	:deep(tr.is-disabled .cell),
	:deep(tr.is-disabled .cell a:not(.activate-link)) {
		color: #acacac;
	}
}
@media screen and (max-width: 767px) {
	.el-table {
		max-height: 45vh !important;
	}
}

h1 {
	margin-bottom: 30px;
}
.show-inactive {
	margin-top: 26px;
}

.el-button {
	min-height: 0;
}

.el-button.is-circle {
	display: inline-block;
	margin: 1px auto;
	padding: 7px;
	letter-spacing: 0;

	&.edit-btn {
		color: $blue;
		background-color: #e1f1f6;
		border-color: #78bcd9;
		margin-right: 10px;

		&:hover,
		&:focus,
		&:active {
			opacity: 1;
			color: #fff;
			background-color: $blue;
			border-color: $blue;
		}
	}
	&.activate-btn {
		color: #57bb61;
		background-color: #e2f7e5;
		border-color: #94e19a;
		margin-left: 40px;

		&:hover,
		&:focus,
		&:active {
			opacity: 1;
			color: #fff;
			background-color: $success-green;
			border-color: $success-green;
		}
	}
}

.createBtnWrap {
	margin-bottom: 0;
	text-align: right;
}

.create-button {
	width: auto;
	height: 28px;
	border: 1px solid $success-green !important;
	border-radius: 20px;
	background-color: #fff !important;
	color: #57bb61;
	font-size: 11px;
	text-transform: uppercase;
	padding: 6px 10px 4px 32px;
	position: relative;
	top: 21px;

	&:hover .plus-icon {
		color: #fff;
		background-color: $success-green;
	}

	.plus-icon {
		font-size: 1.5em;
		line-height: 140%;
		text-align: center;
		padding: 1px 0 0;
		color: #57bb61;
		border: 1px solid $success-green;
		background-color: #e2f7e5;
		border-radius: 20px;
		height: 25px;
		width: 26px;
		position: absolute;
		left: -1px;
		top: -1px;
		transition: color 0.1s, background-color 0.1s;
	}
}

@media screen and (max-width: 480px) {
}
@media screen and (max-width: 767px) {
	h1 {
		text-align: left;
		width: 100%;
		margin-bottom: 20px;
	}

	.show-inactive {
		margin: 0 auto 15px;
		text-align: center;
		width: 100%;
	}

	.createBtnWrap {
		margin-bottom: 25px;
		text-align: center;
	}

	.create-button {
		top: 0;
	}
}
</style>
